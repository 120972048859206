<template>
  <div class="container">
    <navbar title="浏览记录"></navbar>
    <c-sticky :offset-top="$px2vwMin(92)">
      <search-input2
        v-model="keyword"
        @search="search"
      >
        <template #right>
          <div v-if="!manage">
            <span
              class="search-button2"
              @click="showClearDialog = true;"
            >清空</span>
            <span
              class="search-button"
              @click="manage=true;"
            >管理</span>
          </div>
          <span
            class="search-button"
            v-else
            @click="manage=false;"
          >完成</span>
        </template>
      </search-input2>
    </c-sticky>
    <div class="list">
      <toy-list
        @load="onLoad"
        :loading="loading"
        :finished="finished"
        :list="list"
        :openCheckbox="manage"
        ref="toyList"
        toyIdKey="toy_id"
      ></toy-list>
    </div>
    <div
      class="bottom-operate-placeholder"
      v-if="manage"
    ></div>
    <div
      class="bottom-operate"
      v-if="manage"
    >
      <div class="operate-left">
        <van-checkbox
          class="checkbox"
          v-model="$refs.toyList.allChecked"
          :icon-size="$px2vw(32)"
        >全选</van-checkbox>
        <div class="select-explain">已选<span class="select-num">{{$refs.toyList.checkedList.length}}</span>个</div>
      </div>
      <div
        class="operate-button"
        @click="batDelete"
      >删除</div>
    </div>
    <confirm v-model="showClearDialog" @confirm="clearAll" title="确定清空浏览记录？"></confirm>
  </div>
</template>

<script>
import CSticky from "@/components/c-sticky.vue";
import Navbar from "@/components/navbar.vue";
import SearchInput2 from "@/components/search-input2.vue";
import ToyList from "@/components/toy-list.vue";
import { toyBrowseList, toyBrowseDelete } from "@/api/toy.js";
import Confirm from '@/components/confirm.vue';
export default {
  name: "browserRecord",
  components: {
    CSticky,
    Navbar,
    SearchInput2,
    ToyList,
    Confirm,
  },
  data() {
    return {
      keyword: '',
      manage: false,
      loading: false,
      finished: false,
      list: [],
      showClearDialog: false,
      page: 0,
    };
  },
  mounted() {

  },
  methods: {
    search() {
      this.page = 0;
      this.finished = false;
      this.onLoad();
    },
    onLoad() {
      this.loading = true;
      this.page++;
      let data = { page: this.page, name: this.keyword };
      toyBrowseList(data).then(res => {
        if (res.code == 0) {
          if (res.data.length == 0) {
            this.finished = true;
          } 
          if (this.page == 1) {
            this.list = res.data;
          } else {
            this.list = [...this.list, ...res.data];
          }
          this.loading = false;
        } else {
          this.$toast(res.msg);
        }
      })
    },
    clearAll() {
      toyBrowseDelete({ all: 1 }).then(res => {
        if (res.code == 0) {
          this.search();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    batDelete() {
      if (this.$refs.toyList.checkedList.length == 0) {
        this.$toast("请先选择浏览记录");
        return;
      }
      toyBrowseDelete({ id: this.$refs.toyList.checkedList }).then(res => {
        if (res.code == 0) {
          this.manage = false;
          this.search();
        } else {
          this.$toast(res.msg);
        }
      })
    },
  },
  computed: {

  },
  watch: {
    
  }
};
</script>
<style lang="scss" scoped>
.container {
  .search-button {
    font-size: 32px;
    font-weight: 500;
    color: #006afa;
    margin-left: 16px;
    margin-right: 10px;
  }
  .search-button2 {
    font-size: 32px;
    font-weight: 500;
    color: #ff5d5d;
    margin-left: 30px;
    margin-right: 8px;
  }
  .list {
    padding: 20px;
    @include safe-area-inset-bottom(20px);
  }
  .bottom-operate-placeholder {
    height: 120px;
  }
  .bottom-operate {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 60px);
    height: 120px;
    padding: 0 30px;
    background: #ffffff;
    @include safe-area-inset-bottom;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .operate-left {
      display: flex;
      .checkbox {
        font-size: 32px;
        font-weight: 400;
        color: #666666;
      }
      .select-explain {
        margin-left: 40px;
        font-size: 32px;
        font-weight: 400;
        color: #4f4f4f;
        .select-num {
          color: #1aa3ff;
        }
      }
    }
    .operate-button {
      width: 180px;
      height: 70px;
      background: #f65c5c;
      border-radius: 41px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
